<template>
  <v-container>
    <PageHeader
      :pageTitle="pageTitle"
      :pageDescription="$t('pageDescriptions.settingsPassword')"
    />

    <div v-if="adminType !== 'faceforce'" class="mt-6 pa-6 white">
      <v-form ref="form" v-model="isFormValid" v-on:submit.prevent="safeData()">
        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model="password"
              :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
              :rules="[rules.required, rules.min]"
              :type="show1 ? 'text' : 'password'"
              :label="$t('password')"
              :hint="$t('minimum8Chars')"
              counter
              @click:append="show1 = !show1"
            ></v-text-field>
          </v-col>

          <v-col cols="12">
            <v-text-field
              v-model="re_password"
              :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
              :rules="[rules.required, rules.min, passwordConfirmationRule]"
              :type="show2 ? 'text' : 'password'"
              :label="$t('repeatPassword')"
              :hint="$t('minimum8Chars')"
              counter
              @click:append="show2 = !show2"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <div class="d-flex align-end flex-column">
              <v-row>
                <v-btn color="grey" text @click="resetForm()">{{
                  $t("cancel")
                }}</v-btn>
                <v-btn
                  v-if="isFormValid"
                  color="green"
                  dark
                  @click="safeData"
                  :loading="fetchinData"
                  >{{ $t("safe") }}</v-btn
                >
                <v-btn v-else disabled>{{ $t("safe") }}</v-btn>
              </v-row>
            </div>
          </v-col>
        </v-row>
      </v-form>
    </div>

    <div v-else class="mt-6 pa-6 white">
      <h2>Nicht möglich</h2>
      <p>FACEFORCE-Admins können Passwörter nur in mitfitManage anpassen.</p>
    </div>
  </v-container>
</template>

<script>
import store from "@/store";
import manageApi from "@/mixins/manageApi"; // import mixin (for api requests)
import PageHeader from "@/components/ui/PageHeader";

export default {
  name: "ChangePassword",

  components: {
    PageHeader,
  },

  data() {
    return {
      adminType: store.getters["auth/adminType"],
      pageTitle: this.$t("changePassword"),
      pageDescription:
        "Pellentesque in ipsum id orci porta dapibus. Cras ultricies ligula sed magna dictum porta.",
      show1: false,
      show2: false,
      isFormValid: false,
      password: "",
      re_password: "",
      fetchinData: false, // should be true when fetching data
      companyID: store.getters["auth/current_sid"],
      adminID: store.getters["auth/adminid"],
      rules: {
        required: (value) => !!value || this.$t("required"),
        min: (v) => v.length >= 8 || this.$t("minimum8Chars"),
      },
    };
  },

  props: [],

  created: function () {
    // allow to change company?
    this.$store.commit("companyChangeOption/SET_ALLOWED", true);
  },

  methods: {
    resetForm() {
      this.$refs.form.reset();
      this.show1 = false;
      this.show2 = false;
      this.password = "";
      this.re_password = "";
    },

    async safeData() {
      const params = new URLSearchParams();
      params.append("password", this.password);

      this.fetchinData = true;
      let response = await this.patchRequest("admins/" + this.adminID, params); // await data from mixin
      this.fetchinData = false;

      if (response.status === 200) {
        this.$root.snackbarGlobal.snackbar = true;
        this.$root.snackbarGlobal.snackbarText = this.$t("messageUpdated");
        this.$root.snackbarGlobal.errorMessage = "";
        this.$root.snackbarGlobal.snackbarColor = "primary";
        this.$root.snackbarGlobal.snackbarTimeout = 5000;
        this.$refs.form.reset();
      } else {
        this.fetchinData = false;
        this.$root.snackbarGlobal.snackbar = true;
        this.$root.snackbarGlobal.snackbarText = this.$t("messageError");
        this.$root.snackbarGlobal.errorMessage = "";
        this.$root.snackbarGlobal.snackbarColor = "red";
        this.$root.snackbarGlobal.snackbarTimeout = 5000;
      }
    },
  },

  computed: {
    passwordConfirmationRule() {
      return () =>
        this.password === this.re_password || this.$t("passwordsMustMatch");
    },
  },

  /* all requests by mixin */
  mixins: [manageApi],
};
</script>
